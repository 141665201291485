<template>
  <div class="div-cus-mgmt-main">
    <div class="div-cus-mgmt-main-title">
      <h1>Customer Management</h1>
      <el-button v-if="hasPermission($permissionFunctionCode.ADD)" round type="primary" @click="handleCreateCaOpenDialog">Add Customer</el-button>
    </div>
    <div class="div-cus-mgmt-main-body">
      <div class="div-cus-mgmt-main-left">
        <div class="div-cus-mgmt-main-left-tab">
          <el-tabs v-model="cpIsOnlineTabActive" @tab-click="handleCpTabClick">
            <el-tab-pane label="Active" name="ACTIVE"/>
            <el-tab-pane label="Inactive" name="INACTIVE"/>
            <el-tab-pane label="Pending" name="PENDING"/>
          </el-tabs>
        </div>
        <div class="div-cus-mgmt-main-left-search ">
          <el-input
            class="round-input"
            placeholder="Search Company Name"
            v-model="cpSearchCompanyName"
            @keypress.enter.native="handleCpListSearch"
          >
            <el-button type="primary" slot="append" icon="el-icon-search" @click="handleCpListSearch"></el-button>
          </el-input>
        </div>
        <div class="div-cus-mgmt-main-left-list" v-if="cpList.length > 0 && !companyListLoading">
          <div
            v-for="(item, index) in cpList" :key="item.id"
            @click="handleCpListItemClick(index);"
            :class="{'div-active-cp-list-item': index === cpListItemActive}"
          >
            <img
              v-if="item.logoSrc"
              height="40px"
              width="40px"
              :src="item.logoSrc"
              alt=""
            />
            <div class="div-active-cp-list-item-no-logo" v-else> <el-avatar :size="40">{{getAvatarName(item.counterpartyCompanyName)}}</el-avatar></div>
            <div>
              <p>{{item.counterpartyCompanyName}}</p>
              <p v-if="item.isOnlineCounterparty">Online</p>
              <p v-else>Offline</p>
            </div>
            <div class="div-cp-list-item-flagged" v-if="item.isFlagged"><p>Flagged</p></div>
          </div>
        </div>
        <div class="div-company-list-loading" v-else-if="companyListLoading" v-loading="companyListLoading"/>
        <div class="div-company-list-no-data" v-else><p>No Data</p></div>
      </div>
      <div class="div-cus-mgmt-main-right" v-if="caSelected && !cpDetailsLoading">
        <div class="div-cus-mgmt-main-right-top">
          <div class="div-cus-mgmt-main-company-info">
            <div class="div-cus-mgmt-main-company-info-title-btn">
              <h3>Company Overview</h3>
              <div class="div-cus-mgmt-main-company-info-btn">
                <el-button
                  v-if="cpIsOnlineTabActive === 'PENDING' && caSelected.requestedBySysOrganizationId !== sysOrganizationId && hasPermission($permissionFunctionCode.EDIT)"
                  round type="success" size="mini"
                  @click="approveRejectCancelCaReq('approve')"
                >
                  Approve
                </el-button>
                <el-button
                  v-if="cpIsOnlineTabActive === 'PENDING' && caSelected.requestedBySysOrganizationId !== sysOrganizationId && hasPermission($permissionFunctionCode.EDIT)"
                  round type="danger" size="mini"
                  @click="approveRejectCancelCaReq('reject')"
                >
                  Reject
                </el-button>
                <el-tag
                  v-if="cpIsOnlineTabActive === 'PENDING' && !hasPermission($permissionFunctionCode.EDIT)"
                  round type="warning" size="mini"
                >
                  Pending Approval
                </el-tag>
                <el-button
                  v-if="cpIsOnlineTabActive === 'PENDING' && caSelected.requestedBySysOrganizationId === sysOrganizationId && hasPermission($permissionFunctionCode.EDIT)"
                  round type="danger" size="mini"
                  @click="approveRejectCancelCaReq('cancel')"
                >
                  Cancel Request
                </el-button>
                <el-button v-if="!caSelected.isOnlineCounterparty && hasPermission($permissionFunctionCode.MANAGE_OFFLINE_USER)" round type="primary" size="mini" @click="isEditOfflineUser = false; dialogAddEditUser = true">Add User</el-button>
                <el-button v-if="caSelected != null && hasPermission($permissionFunctionCode.EDIT)" round size="mini" outline @click="handleEditOfflineCompanyClick">Edit</el-button>
                <el-dropdown v-if="hasPermission($permissionFunctionCode.EDIT)" trigger="click" @command="handleDeactiveFlagCompany" style="cursor: pointer;">
                  <span style="padding: 5px 6px;">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="DEFAULT_BANK">Edit Default Bank</el-dropdown-item>
                    <el-dropdown-item command="ACTIVATE" v-if="caSelected.status === 'INACTIVE'">Activate Company</el-dropdown-item>
                    <el-dropdown-item command="DEACTIVATE" v-else>Deactivate Company</el-dropdown-item>
                    <el-dropdown-item command="UNFLAG" v-if="caSelected.isFlagged">Unflag Company</el-dropdown-item>
                    <el-dropdown-item command="FLAG" v-else>Flag Company</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="div-cus-mgmt-main-company-info-body">
              <div class="div-cus-mgmt-main-company-info-body-main">
                <div div class="company-logo-name">
                  <img
                    v-if="caSelected && caSelected.logoSrc"
                    height="50px"
                    width="50px"
                    :src="caSelected.logoSrc"
                    alt=""
                  />
                  <el-avatar v-else :size="100">
                  <span class="company-info-avatar">{{cpCompany ? getAvatarName(cpCompany.companyName) : ""}}</span></el-avatar>
                </div>
                <div class="div-cus-mgmt-main-company-info-body-details">
                  <div>
                    <h4>Company Name:</h4>
                    <p>{{cpCompany && cpCompany.companyName ? cpCompany.companyName  : '-'}}</p>
                  </div>
                  <div>
                    <h4>UEN/Business Incorporation No:</h4>
                    <p>{{cpCompany && cpCompany.businessIncorporationNumber ? cpCompany.businessIncorporationNumber  : '-'}}</p>
                  </div>
                  <div>
                    <h4>Country of Incorporation:</h4>
                    <p>{{cpCompany && cpCompany.incorporationCountry ? cpCompany.incorporationCountry  : '-'}}</p>
                  </div>
                  <div>
                    <h4>Incorporation Date:</h4>
                    <p>{{cpCompany && cpCompany.incorporationDate ? cpCompany.incorporationDate  : '-'}}</p>
                  </div>
                  <div>
                    <h4>City/State:</h4>
                    <p>{{cpCompany && cpCompany.cityOrState ? cpCompany.cityOrState  : '-'}}</p>
                  </div>
                  <div>
                    <h4>Street Name:</h4>
                    <p>{{cpCompany && cpCompany.streetName ? cpCompany.streetName  : '-'}}</p>
                  </div>

                  <div>
                    <h4>Building Name:</h4>
                    <p>{{cpCompany && cpCompany.buildingName ? cpCompany.buildingName  : '-'}}</p>
                  </div>
                  <div>
                    <h4>Unit No:</h4>
                    <p>{{cpCompany && cpCompany.unitNumber ? cpCompany.unitNumber  : '-'}}</p>
                  </div>
                  <div>
                    <h4>Postal Code:</h4>
                    <p>{{cpCompany && cpCompany.postal ? cpCompany.postal  : '-'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="div-cus-mgmt-main-credit-info">
            <div class="div-cus-mgmt-main-credit-info-title-btn">
              <h3>Credit Limit</h3>
              <el-button v-if="hasPermission($permissionFunctionCode.EDIT)" round size="mini" outline @click="handleEditCreditInfoClick">Edit</el-button>
            </div>
            <div class="div-cus-mgmt-main-credit-info-body">
              <div class="div-cus-mgmt-main-credit-info-body-main">
                <div>
                  <h4>Credit Days:</h4>
                  <p>{{caCreditInfo.creditDays}}</p>
                </div>
                <div>
                  <h4>Credit Limit:</h4>
                  <p>{{caCreditInfo.creditLimitDisplay}}</p>
                </div>
                <div>
                  <h4>Credit Used:</h4>
                  <p>{{caCreditInfo.totalCreditUsedDisplay}}</p>
                </div>
                <div>
                  <h4>Balance Credit:</h4>
                  <p>{{caCreditInfo.totalCreditBalanceDisplay}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-table
          :data="cpUsers"
          height="100%"
          width="100%"
          min-width="800px"
          :cell-style="{padding: '0', height: '70px'}"
          :header-cell-style="{color: '#4C565C', backgroundColor: '#F4F6FA'}"
        >
          <el-table-column prop="salutation" min-width="100px">
            <template v-slot:header>Salutation</template>
            <template v-slot="scope">
              <div>{{scope.row.salutation ? scope.row.salutation : '-'}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="firstName" min-width="150px">
            <template v-slot:header>First Name</template>
            <template v-slot="scope">
              <div>{{scope.row.firstName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="lastName" min-width="150px">
            <template v-slot:header>Last Name</template>
            <template v-slot="scope">
              <div>{{scope.row.lastName}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="mobile" min-width="100px">
            <template v-slot:header>Contact No.</template>
            <template v-slot="scope">
              <div>{{scope.row.mobile ? scope.row.mobile : '-'}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="officePhoneNumber" min-width="100px">
            <template v-slot:header>Office No.</template>
            <template v-slot="scope">
              <div>{{scope.row.officePhoneNumber ? scope.row.officePhoneNumber : '-'}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="email" min-width="150px">
            <template v-slot:header>Email Address</template>
            <template v-slot="scope">
              <div>{{scope.row.email}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="designation" min-width="100px">
            <template v-slot:header>Designation</template>
            <template v-slot="scope">
              <div>{{scope.row.designation}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="!caSelected.isOnlineCounterparty && hasPermission($permissionFunctionCode.MANAGE_OFFLINE_USER)" min-width="50px">
            <template v-slot="scope">
              <el-button round size='mini' icon="el-icon-edit" @click="handleEditOfflineUserClick(scope.row)"/>
            </template>
          </el-table-column>
          <el-table-column v-if="!caSelected.isOnlineCounterparty && hasPermission($permissionFunctionCode.MANAGE_OFFLINE_USER)" min-width="50px">
            <template v-slot="scope">
              <el-button round size='mini' :icon="scope.row.isActive ? 'el-icon-delete' : 'el-icon-refresh-left'" :style="scope.row.isActive ? 'background-color: #CC3333; color: #FFFFFF;' : 'background-color: green; color: #FFFFFF;'" @click="activateDeactivateOfflineUser(scope.row)"/>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="div-cus-mgmt-main-right-loading" v-else-if="cpDetailsLoading" v-loading="cpDetailsLoading"/>
      <div class="div-cus-mgmt-main-right-no-data" v-else><p>No Data</p></div>
    </div>
    <el-dialog
      width="980px"
      class="el-dialog-create-edit-ca"
      :title="`${isEditOfflineCompany ? 'Edit Company' : 'Create Credit Association'}`"
      :visible.sync="dialogCreateEditCa"
      @close="resetCaForm"
    >
      <el-tabs v-if="!isEditOfflineCompany" v-model="createCaOnlineOffline" @tab-click="handleCreateCaTabClick">
        <el-tab-pane label="Online Company" name="SYS_ONLINE_ORG"/>
        <el-tab-pane label="Offline Company" name="SYS_OFFLINE_ORG"/>
      </el-tabs>
      <el-form :model="caForm" :rules="rules" ref="caForm">
        <!-- <div style="margin-bottom: 10px;">
          <p style="margin-top: 0">Company Logo</p>
          <el-upload
            :action="fileUploadUrl"
            :accept="'.jpg,.png'"
            :limit="1"
            :file-list="caForm.logoFileList"
            :on-success="handleCpLogoUploadSuccess()"
            :before-remove="handleCpLogoBeforeRemove"
            :on-remove="handleCpLogoRemove()"
          >
            <el-button
              plain round
              type="primary"
              style="border: 0; font-size: 14px; width: 200px;"
            >
              Upload
            </el-button>
            <div slot="tip" class="el-upload__tip">Only PNG/JPG images can be uploaded, and no more than 4MB</div>
          </el-upload>
        </div> -->
        <div class="div-create-edit-ca-form-row" >
          <template v-if="createCaOnlineOffline !== '' || (isEditOfflineCompany && ! caSelected?.isOnlineCounterparty)">
          <el-form-item
            label="Company Name"
            prop="companyName"
          >
            <el-input v-model="caForm.companyName"  v-if="createCaOnlineOffline === 'SYS_OFFLINE_ORG' || isEditOfflineCompany" :rules="REQ_RULE" />
            <el-select v-else v-model="caForm.company" filterable clearable @change="handleCompanyNameSelect" value-key="id" :rules="REQ_RULE">
              <el-option
                v-for="item in createCompanyList"
                :key="item.id" :label="item.companyName" :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="UEN/Business Incorporation No."
            prop="businessIncorporationNumber"
          >
            <el-input :disabled="createCaOnlineOffline !== 'SYS_OFFLINE_ORG' && !isEditOfflineCompany" v-model="caForm.businessIncorporationNumber"/>
          </el-form-item>
          <el-form-item
            v-if="createCaOnlineOffline === 'SYS_OFFLINE_ORG' || isEditOfflineCompany"
            label="Company Abbreviation"
            prop="abbreviation"
          >
            <el-input v-model="caForm.abbreviation" @input="handleChangeToCaps"/>
          </el-form-item>
        </template>
        <template v-if="createCaOnlineOffline === 'SYS_OFFLINE_ORG' || (isEditOfflineCompany && !caSelected?.isOnlineCounterparty)">
          <el-form-item
            label="Incorporation Date"
            prop="incorporationDate"
          >
            <el-date-picker type="date"
              v-model="caForm.incorporationDate"
              format="dd-MM-yyyy"
              value-format='yyyy-MM-dd HH:mm:ss'
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item
            label="Company Type"
            prop="type"
          >
            <el-select
              v-model="caForm.companyType"
            >
              <el-option
                v-for="item in caOrgTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="General Email"
            prop="email"
          >
            <el-input v-model="caForm.email"/>
          </el-form-item>
          <el-form-item
            label="Fax"
            prop="fax"
          >
            <el-input v-model="caForm.fax"/>
          </el-form-item>
          <el-form-item
            label="Country of Incorporation"
            prop="incorporationCountry"
          >
            <el-input v-model="caForm.incorporationCountry"/>
          </el-form-item>
          <el-form-item
            label="Street Name"
            prop="streetName"
          >
            <el-input v-model="caForm.streetName"/>
          </el-form-item>
          <el-form-item
            label="Building Name"
            prop="buildingName"
          >
            <el-input v-model="caForm.buildingName"/>
          </el-form-item>
        </template>
        <template  v-if="createCaOnlineOffline === 'SYS_OFFLINE_ORG' || (isEditOfflineCompany && ! caSelected?.isOnlineCounterparty)">
          <el-form-item
             label="Unit Number"
            prop="unitNumber"
          >
            <el-input v-model="caForm.unitNumber"/>
          </el-form-item>
          <el-form-item
            label="Postal/Zip Code"
            prop="postal"
          >
            <el-input v-model="caForm.postal"/>
          </el-form-item>

        </template>
        <template v-if="currentCompanySettings.pricingTierCheck">
          <el-form-item   label="Pricing Tier" prop="pricingTier">
            <el-select v-model="caForm.pricingTier">
              <el-option
                v-for="tier in tiers"
                :key="tier.tierId"
                :label="tier.title"
                :value="tier.tierId"
              />
            </el-select>
          </el-form-item>
          <el-form-item  label="Company Code (SAGE 300)" prop="companyCode">
              <el-select
                v-model="caForm.victoryCompanyCode" placeholder="Choose or input"
                filterable allow-create
                @visible-change="getVictoryCompanyCodeList($event,caForm.id)"
                @change="handleChangeVictoryCompanyCode">
              <el-option
                v-for="item in victoryCompanyCodeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item  label="Group Code (SAGE 300)" prop="groupCode">
            <el-input disabled v-model="caForm.groupCode"></el-input>
          </el-form-item>
          <el-form-item  label="Account Set (SAGE 300)" prop="accountSet">
              <el-select
                v-model="caForm.accountSet" placeholder="Plese select"
                filterable
                @visible-change="getVictoryAccountSetList($event)">
              <el-option
                v-for="item in victoryAccountSetList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item  label="Billing Cycle (SAGE 300)" prop="billingCycle">
            <el-select
                v-model="caForm.billingCycle" placeholder="Plese select"
                filterable
                @visible-change="getVictoryBillingCycleList($event)">
              <el-option
                v-for="item in victoryBillingCycleList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item  label="Terms (SAGE 300)" prop="terms">
            <!-- @change="handleChangeVictoryTerms" -->
            <el-select
                v-model="caForm.terms" placeholder="Plese select"
                filterable
                @visible-change="getVictoryTermsList($event)">
              <el-option
                v-for="item in victoryTermsList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </template>
      </div>
      </el-form>
      <div class="el-dialog__footer">
        <el-button
          v-if="!isEditOfflineCompany"
          round
          type="primary"
          @click=" createCaOnlineOffline === 'SYS_ONLINE_ORG' ? requestOnlineCa() : requestOfflineCa()"
        >
          Create
        </el-button>
        <el-button v-else-if="caSelected?.isOnlineCounterparty" round type="primary" @click="updateOnlineCompany">Save</el-button>
        <el-button v-else round type="primary" @click="updateOfflineCompany">Save</el-button>
        <el-button
          outline
          round
          @click="dialogCreateEditCa = false"
        >
          Cancel
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="Edit Credit Information"
      :visible.sync="dialogEditCreditInfo"
      width="40%"
    >
      <div>
        <div>
          <p style="margin-top: 0">Credit Days</p>
          <el-input-number
            @blur="handleCreditDaysBlur"
            v-model="caCreditInfo.creditDays"
            :controls="false"
            :precision="0"
            :min="0"
          />
        </div>
        <div>
          <p>Credit Limit</p>
          <el-input
            @blur="handleCreditLimitBlur"
            v-model="caCreditInfo.creditLimit"
            :controls="false"
            :precision="0"
            :min="0"
          >
            <div slot="append">USD</div>
          </el-input>
        </div>
      </div>
      <div class="el-dialog__footer">
        <el-button
          round
          type="primary"
          @click="updateCreditInfo"
        >
          Save
        </el-button>
        <el-button
          outline
          round
          @click="dialogEditCreditInfo = false"
        >
          Cancel
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      class="el-dialog-create-edit-user"
      :title="isEditOfflineUser ? 'Edit User' : 'Add User'"
      :visible.sync="dialogAddEditUser"
    >
      <el-form :model="offlineUserForm" :rules="offlineUserFormRules" ref="offlineUserForm">
        <div class="div-create-edit-user-form-dialog-top">
          <el-form-item
            label="Salutation"
            prop="salutation"
          >
            <el-select
              v-model="offlineUserForm.salutation"
            >
              <el-option
                v-for="item in salutationList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="First Name"
            prop="firstName"
          >
            <el-input v-model="offlineUserForm.firstName"/>
          </el-form-item>
          <el-form-item
            label="Last Name"
            prop="lastName"
          >
            <el-input v-model="offlineUserForm.lastName"/>
          </el-form-item>
        </div>
        <div class="div-create-edit-user-form-dialog-mid">
          <el-form-item
            label="Contact No."
            prop="mobile"
          >
            <phone-input v-model="offlineUserForm.mobile"/>
          </el-form-item>
          <el-form-item
            label="Office No."
            prop="officePhoneNumber"
          >
            <phone-input v-model="offlineUserForm.officePhoneNumber"/>
          </el-form-item>
          <el-form-item
            label="Email Address"
            prop="email"
          >
            <el-input v-model="offlineUserForm.email"/>
          </el-form-item>
          <el-form-item
            label="Designation"
            prop="designation"
          >
            <el-input v-model="offlineUserForm.designation"/>
          </el-form-item>
        </div>
      </el-form>
      <div class="el-dialog__footer">
        <el-button
          v-if="!isEditOfflineUser"
          round
          type="primary"
          @click="createOfflineUser"
        >
          Add
        </el-button>
        <el-button
          v-else
          round
          type="primary"
          @click="updateOfflineUser"
        >
          Save
        </el-button>
        <el-button
          outline
          round
          @click="resetOfflineUserForm(); dialogAddEditUser = false"
        >
          Cancel
        </el-button>
      </div>
    </el-dialog>
    <el-dialog v-if="caSelected" :visible="dialogBank" title="Default Bank" @close="dialogBank = false">
      <div v-loading="bankLoading">
        <update-bank
          v-for="(item, index) in bankAccList"
          :key="index"
          :refKey="index===0?'companyBank':'chaserBank'"
          :category="item.category"
          :sysOrganizationId="caSelected.counterpartySysOrganizationId"
          :companyDefaultBank="companyDefaultBank"
          :chaserDefaultBank="chaserDefaultBank"
          :data="item.bankList"
          isViewOnly
          hasRadio
          @handleBankRadioSelectChange="handleBankRadioSelectChange"
        />
      </div>
      <div class="el-dialog__footer" style="margin-right: 20px;">
        <el-button outline round @click="dialogBank = false">
          Cancel
        </el-button>
        <el-button round  type="primary" @click="handleConfirmDefaultBank">
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PhoneInput from '@/components/PhoneInput'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import UpdateBank from '../systemManager/companyManager/components/UpdateBank.vue'
import { getVictoryCompanyCodeListReq,getVictoryAccountSetListReq,getVictoryBillingCycleListReq,getVictoryTermsListReq } from '@/services/modules'
import{ REQ_RULE }from '@/constants.js'
export default {
  name: 'CustomerManagement',
  mixins: [baseMixin, baseTableMixin],
  components: {
    PhoneInput,
    UpdateBank
  },
  computed: {
    ...mapState(['roles', 'currentCompany', 'bankList' ,'currentCompanySettings'],),

  },
  watch: {
    'currentCompany.id': {
      handler (value) {
        if (value) {
          this.$router.go(0)
        }
      },
      immediate: false
    },
    'caForm.victoryCompanyCode': {
      handler(newVal) {
        const isRequired = !!newVal;

        this.$set(this.rules, 'accountSet', isRequired ?
          [{ required: true, message: 'Account Set is required', trigger: ['blur', 'change'] }] :
          [{ required: false, message: 'Account Set is required', trigger: ['blur', 'change'] }]
        );

        this.$set(this.rules, 'billingCycle', isRequired ?
          [{ required: true, message: 'Billing Cycle is required', trigger: ['blur', 'change'] }] :
          [{ required: false, message: 'Billing Cycle is required', trigger: ['blur', 'change'] }]
        );

        this.$set(this.rules, 'terms', isRequired ?
          [{ required: true, message: 'Terms are required', trigger: ['blur', 'change'] }] :
          [{ required: false, message: 'Terms are required', trigger: ['blur', 'change'] }]
        );

        // Re-validate the form to apply new rules
        this.$refs.caForm.validate();
      },
      immediate: false // Only react to changes, not immediately
    }
  },
  data () {
    const rule = [{
      required: true,
      message: 'This field is required',
      trigger: ['blur', 'change']
    }]
    const caForm = {
      logoFileList: [],
      bdnLogoIds: [],
      company: null,
      companyName: null,
      businessIncorporationNumber: null,
      abbreviation: null,
      incorporationDate: null,
      companyType: null,
      email: null,
      fax: null,
      incorporationCountry: null,
      streetName: null,
      buildingName: null,
      unitNumber: null,
      postal: null,
      pricingTier: null,
      caId: null,
      victoryCompanyCode: null,
      groupCode: "ADHOC",
      billingCycle: null,
      terms: null,
    }
    const offlineUserForm = {
      sysOrganizationId: null,
      sysOfflineOrganizationId: null,
      salutation: null,
      firstName: null,
      lastName: null,
      mobile: '+65',
      officePhoneNumber: '+65',
      email: null,
      designation: null
    }
    const caCreditInfo = {
      id: null,
      creditDays: null,
      creditLimit: null
    }

    const rules = {
      // companyName: rule,
      // company: rule,
      abbreviation: [{
        required: true,
        pattern: /^[a-zA-Z]{3,6}$/,
        message: 'Must contain 3-6 alphabet',
        trigger: ['blur']
      }],
      businessIncorporationNumber: rule,
      email: [{ required: false, validator: this.emailValidation, trigger: ['blur'] }]
    }
    const offlineUserFormRules = {
      firstName: rule,
      lastName: rule,
      designation: rule,
      email: [{ required: true, validator: this.emailRequiredValidation, trigger: ['blur'] }]
    }
    const caOrgTypeList = [
      { label: 'Trading', value: 'TRADING' },
      { label: 'Agent', value: 'AGENT' },
      { label: 'Lawyer', value: 'LAWYER' },
      { label: 'Lab', value: 'LAB' },
      { label: 'Financial Service Provider', value: 'FINANCIAL_SERVICE_PROVIDER' }
    ]
    const salutationList = ['Mr.', 'Mrs.', 'Miss', 'Dr.', 'Ms.', 'Prof.', 'Rev.', 'Lady.', 'Sir.', 'Capt.']
    return {
      REQ_RULE,
      rules,
      contentTitle: 'Customer Management',
      logoSrc: '',
      offlineUserFormRules,
      caForm,
      offlineUserForm,
      caCreditInfo,
      cpList: [],
      dialogCreateEditCa: false,
      dialogEditCreditInfo: false,
      dialogAddEditUser: false,
      caOrgTypeList,
      salutationList,
      companyList: [],
      createCompanyList: [],
      cpIsOnlineTabActive: 'ACTIVE',
      createCaOnlineOffline: 'SYS_ONLINE_ORG',
      caSelected: null,
      cpCompany: null,
      cpUsers: [],
      cpSearchCompanyName: null,
      sysOrganizationId: this.$store.state.currentCompany.id,
      isEditOfflineCompany: false,
      isEditOfflineUser: false,
      cpDetailsLoading: false,
      companyListLoading: false,
      cpListItemActive: null,
      fileUploadUrl: '',
      dialogBank: false,
      bankLoading: true,
      bankAccList: [],
      companyDefaultBank: null,
      chaserDefaultBank: null,
      isShowWarningIcon: false, //is show 'Pending' tab waning icon
      tiers: [],
      victoryCompanyCodeList:[],
      victoryAccountSetList:[],
      victoryBillingCycleList:[],
      victoryTermsList:[],
    }
  },
  async created () {
    this.fileUploadUrl = this.$apis.baseUrl + this.$apis.uploadFile
    this.getCps().then(() => {
      this.handleCpListItemClick(0)
      this.$store.dispatch('getBankList', this.currentCompany.id).then(() => {
        this.getbankList()
      })
    })
    this.getOnlineCompanies().then(data => {
      this.getAllCps()
    })
    await this.getPricingTierDefinition()
  },
  methods: {
    /* Validation */
    emailValidation (rule, value, callback) {
      const regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if (!regex.test(value) && value && value.trim()) {
        callback(new Error('Please enter a valid email address.'))
      } else {
        callback()
      }
    },
    emailRequiredValidation (rule, value, callback) {
      if (!value) {
        callback(new Error('This field is required'))
      } else {
        const regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
        if (!regex.test(value)) {
          callback(new Error('Please enter a valid email address.'))
        } else {
          callback()
        }
      }
    },
    /* LHS - Cp List */
    handleCpTabClick () {
      this.cpListItemActive = null
      this.getCps().then(() => {
        this.cpCompany = null
        this.cpUsers = null
        this.caSelected = null
      })
    },
    handleChangeToCaps (value) {
      this.caForm.abbreviation = value.toUpperCase()
    },
    handleCpListItemClick (index) {
      this.cpListItemActive = index
      this.caSelected = this.cpList[index]
      this.caCreditInfo.creditDays = this.caSelected.creditDays
      this.caCreditInfo.creditLimit = this.caSelected.creditLimit
      this.chaserDefaultBank = this.caSelected.chaserBank
      this.companyDefaultBank = this.caSelected.companyBank
      this.formatCreditInfoDisplay()
      const promises = []
      this.cpDetailsLoading = true
      if (this.caSelected.isOnlineCounterparty) {
        promises.push(this.getOnlineUsersByCompanyId(this.caSelected.counterpartySysOrganizationId))
      } else {
        promises.push(this.getOfflineUsersByCompanyId(this.caSelected.counterpartySysOrganizationId))
      }
      promises.push(this.getCompanyById(this.caSelected.counterpartySysOrganizationId))
      Promise.all(promises)
        .then(results => {
          this.cpCompany = results[1].cpCompany
          this.cpUsers = results[0].cpUsers
          this.cpDetailsLoading = false
        })
    },
    /* RHS - Company Overview */
    handleCreateCaOpenDialog () {
      this.createCaOnlineOffline = 'SYS_ONLINE_ORG'
      this.isEditOfflineCompany = false
      this.dialogCreateEditCa = true
    },
    handleCreateCaTabClick () {
      this.resetCaForm()
    },
    handleCompanyNameSelect (company) {

      this.caForm.businessIncorporationNumber = company.businessIncorporationNumber
    },
    handleCpListSearch () {
      this.getCps().then(data => {
        if (this.cpSearchCompanyName.trim()) {
          this.cpList = data.filter(d => d.counterpartyCompanyName.toLowerCase().includes(this.cpSearchCompanyName.toLowerCase()))
        }
      })
    },
    handleEditOfflineCompanyClick () {
      this.createCaOnlineOffline = ''
      this.isEditOfflineCompany = true
      let pricingTier = this.caSelected.pricingTier
      let caId = this.caSelected.id
      this.caForm = { ...this.cpCompany, caId, pricingTier }
      if (this.caSelected.counterpartyCompanyLogo.length > 0) {
        this.caForm.logoFileList = [this.caSelected.counterpartyCompanyLogo[0]]
      }
      getVictoryCompanyCodeListReq(this.caSelected.counterpartySysOrganizationId).then((data) => {
        this.victoryCompanyCodeList = data
      })
      this.caForm.groupCode = "ADHOC",
      this.dialogCreateEditCa = true
    },
    handleEditOfflineUserClick (user) {
      this.isEditOfflineUser = true
      this.offlineUserForm = this.$_.cloneDeep(user)
      this.dialogAddEditUser = true
    },
    activateDeactivateOfflineUser (user) {
      this.$request.put({
        url: `${this.$apis.offlineOrganizationContact}/${user.id}/active/${!user.isActive}`
      }).then(res => {
        if (res?.code === 1000) {
          user.isActive ? this.$message.success('User has been deactivated.') : this.$message.success('User has been activated.')
          this.cpDetailsLoading = true
          this.getOfflineUsersByCompanyId(this.caSelected.counterpartySysOrganizationId).then(result => {
            this.cpUsers = result.cpUsers
            this.cpDetailsLoading = false
          })
        }
      })
    },
    resetCaForm () {
      this.caForm = {
        logoFileList: [],
        bdnLogoIds: [],
        company: null,
        companyName: null,
        businessIncorporationNumber: null,
        abbreviation: null,
        incorporationDate: null,
        companyType: null,
        email: null,
        fax: null,
        incorporationCountry: null,
        streetName: null,
        buildingName: null,
        unitNumber: null,
        postal: null,
        pricingTier: null,
        caId: null,
        victoryCompanyCode: null,
        groupCode: "ADHOC",
        billingCycle: null,
        terms: null,
      }
      this.$refs.caForm.resetFields()
    },
    resetOfflineUserForm () {
      this.offlineUserForm = {
        sysOrganizationId: null,
        sysOfflineOrganizationId: null,
        salutation: null,
        firstName: null,
        lastName: null,
        mobile: '+65',
        officePhoneNumber: '+65',
        email: null,
        designation: null,
        pricingTier: null,
        caId: null,
        victoryCompanyCode: null,
        groupCode: "ADHOC",
        billingCycle: null,
        terms: null,
      }
      this.$refs.offlineUserForm.resetFields()
    },
    handleCreditDaysBlur (e) {
      if (!e.target.value) {
        this.caCreditInfo.creditDays = 0
      }
    },
    handleCreditLimitBlur (e) {
      if (!e.target.value) {
        this.caCreditInfo.creditLimit = 0
      }
    },
    handleEditCreditInfoClick () {
      this.dialogEditCreditInfo = true
      this.caCreditInfo.creditDays = this.caSelected.creditDays
      this.caCreditInfo.creditLimit = this.caSelected.creditLimit
    },
    handleCpLogoUploadSuccess () {
      return (res, file, fileList) => {
        if (res.code === 1000) {
          const { data } = res
          fileList[0].id = data.id
          fileList[0].url = data.path
          this.caForm.bdnLogoIds = [data.id]
        }
      }
    },
    handleCpLogoBeforeRemove (file) {
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.deleteFile,
          data: [file.id]
        }).finally(() => {
          resolve()
        })
      })
    },
    handleCpLogoRemove () {
      this.caForm.bdnLogoIds = []
    },
    getAllCps () {
      this.$request.get({
        url: `${this.$apis.creditAssociations}?pageSize=9999&filter=sysOrganizationId:'${this.currentCompany.id}'`
      }).then(res => {
        if (res?.code === 1000) {
          const list = res?.data?.content
          if (list?.length > 0) {
            if (list.findIndex(i => i.status.toUpperCase() === 'PENDING') !== -1) {
              this.isShowWarningIcon = true
            }
          }
          this.createCompanyList = this.$_.cloneDeep(this.companyList)
          res.data.content.forEach(cp => {
            const i = this.createCompanyList.findIndex(d => d.id === cp.counterpartySysOrganizationId)
            if (i !== -1) {
              this.createCompanyList.splice(i, 1)
            }
          })
          const j = this.createCompanyList.findIndex(d => d.id === this.currentCompany.id)
          this.createCompanyList.splice(j, 1)
        }
      })
    },

    async getCps () {
      this.companyListLoading = true
      this.cpDetailsLoading = true
      const res = await (
        this.$request.get({
          url: `${this.$apis.creditAssociations}?pageSize=9999&filter=status:'${this.cpIsOnlineTabActive}' and sysOrganizationId:'${this.currentCompany.id}'`
        })
      )
      if (res?.code === 1000) {

        this.cpList = res.data.content
        const files = this.cpList.filter(d => d.counterpartyCompanyLogo.length > 0).map(d => d.counterpartyCompanyLogo[0])
        if (files.length > 0) {
          this.downloadMultipleFileMixin(files).then(filesRes => {
            if (filesRes && filesRes !== '') {
              const logoMap = new Map(Object.entries(filesRes))
              this.cpList.forEach(cp => {
                if (cp.counterpartyCompanyLogo.length > 0) {
                  cp.logoSrc = cp.counterpartyCompanyLogo[0].id ? logoMap.get(cp.counterpartyCompanyLogo[0]?.id) : null
                }
              })
            }
            this.cpDetailsLoading = false
            this.companyListLoading = false
          })
        } else {
          this.cpDetailsLoading = false
          this.companyListLoading = false
        }
        return res.data.content
      }
    },
    setLogoSrcList (id, url, avatarName) {
      this.logoSrcList.push({ id, url, avatarName })
      if (!this.logoSrc && id === this.currentCompany.id) {
        this.logoSrc = url
        this.avatarName = avatarName
      }
    },
    requestOnlineCa () {
      this.$refs.caForm.validate(valid => {
        if (valid) {
          const{ victoryCompanyCode, groupCode, accountSet,billingCycle,terms } = this.caForm
          const data = {
            sysOrganizationId: this.currentCompany.id,
            onlineCounterpartySysOrganizationId: this.caForm.company.id,
            ...this.getVctoryAddtionalFields()
          }
          this.$request.post({
            url: this.$apis.postOnlineCounterparty,
            data: data
          }).then(res => {
            if (res?.code === 1000) {
              this.$message.success('Credit Association has been requested.')
              this.resetCaForm()
              this.cpIsOnlineTabActive = 'PENDING'
              this.cpListItemActive = null
              this.dialogCreateEditCa = false
              this.getCps()
            }
          })
        }
      })
    },
    requestOfflineCa () {
      this.$refs.caForm.validate(valid => {
        if (valid) {
          const sysOfflineOrganization = this.getOfflineCaFormData()
          const data = {
            sysOrganizationId: this.currentCompany.id,
            victoryCompanyCode: sysOfflineOrganization.victoryCompanyCode,
            groupCode: sysOfflineOrganization.groupCode,
            billingCycle: sysOfflineOrganization.billingCycle,
            terms: sysOfflineOrganization.terms,
            accountSet: sysOfflineOrganization.accountSet,
            createdByOnlineSysOrganizationId: sysOfflineOrganization.createdByOnlineSysOrganizationId,
            sysOfflineOrganization: sysOfflineOrganization,
          }
          this.$request.post({
            url: this.$apis.postOfflineCounterparty,
            data: data
          }).then(res => {
            if (res?.code === 1000) {
              this.$message.success('Credit Association has been created.')
              this.resetCaForm()
              this.cpIsOnlineTabActive = 'ACTIVE'
              this.cpListItemActive = null
              this.dialogCreateEditCa = false
              this.getCps()
            }
          })
        }
      })
    },
    approveRejectCancelCaReq (action) {
      this.$request.post({
        url: `${this.$apis.creditAssociations}/${this.caSelected.id}/${action}`
      }).then(res => {
        if (res?.code === 1000) {
          if (action === 'approve') {
            this.$message.success('Credit Association request has been approved.')
            this.cpIsOnlineTabActive = 'ACTIVE'
          } else if (action === 'reject') {
            this.$message.success('Credit Association request has been rejected.')
          } else {
            this.$message.success('Credit Association request has been cancelled.')
          }
          this.getCps()
          this.cpListItemActive = null
          this.caSelected = null
          this.cpCompany = null
          this.cpUsers = null
        }
      })
    },
    updateFlag (isFlagged) {
      this.$request.put({
        url: `${this.$apis.creditAssociations}/${this.caSelected.id}/flag/${isFlagged}`
      }).then(res => {
        if (res?.code === 1000) {
          isFlagged ? this.$message.success('Credit Association has been flagged.') : this.$message.success('Credit Association has been unflagged.')
          this.caSelected.isFlagged = isFlagged
        }
      })
    },
    updateActivation (isActive) {
      this.$request.put({
        url: `${this.$apis.creditAssociations}/${this.caSelected.id}/active/${isActive}`
      }).then(res => {
        if (res?.code === 1000) {
          if (isActive) {
            this.$message.success('Credit Association has been activated')
            this.cpIsOnlineTabActive = 'ACTIVE'
            this.cpListItemActive = null
          } else {
            this.$message.success('Credit Association has been deactivated')
            this.cpIsOnlineTabActive = 'INACTIVE'
            this.cpListItemActive = null
          }
          this.getCps()
          this.caSelected = null
          this.cpCompany = null
          this.cpUsers = null
        }
      })
    },
    updateCreditInfo () {
      this.caCreditInfo.id = this.caSelected.id
      this.$request.put({
        url: `${this.$apis.creditAssociationsUpdateCreditInformation}`,
        data: this.caCreditInfo
      }).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Credit Information has been updated')
          this.caSelected.creditLimit = this.caCreditInfo.creditLimit
          this.caSelected.creditDays = this.caCreditInfo.creditDays
          this.caSelected.totalCreditBalance = this.caCreditInfo.creditLimit - this.caSelected.totalCreditUsed
          this.formatCreditInfoDisplay()
          this.dialogEditCreditInfo = false
        }
      })
    },
    getOnlineUsersByCompanyId (id) {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.sharedUserBaseUrl}?organizationId=${id}`,
        }).then(res => {
          if (res?.code === 1000) {
            const cpUsers = res?.data?.records
            resolve({ cpUsers })
          }
        })
      })
    },
    getOfflineUsersByCompanyId (cpSysOrgId) {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.offlineOrganizationContact}?filter=sysOfflineOrganizationId:'${cpSysOrgId}'`
        }).then(res => {
          if (res?.code === 1000) {
            const cpUsers = res.data.content
            resolve({ cpUsers })
          }
        })
      })
    },
    getCompanyById (id) {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.sharedCompanyBaseUrl}/${id}`
        }).then(res => {
          if (res?.code === 1000 && res.data) {
            const cpCompany = res.data
            resolve({ cpCompany })
          }
        })
      })
    },
    createOfflineUser () {
      this.$refs.offlineUserForm.validate(valid => {
        if (valid) {
          this.offlineUserForm.sysOrganizationId = this.currentCompany.id
          this.offlineUserForm.sysOfflineOrganizationId = this.caSelected.counterpartySysOrganizationId
          this.$request.post({
            url: `${this.$apis.offlineOrganizationContact}`,
            data: this.offlineUserForm
          }).then(res => {
            if (res?.code === 1000) {
              this.$message.success('User has been created')
              this.getOfflineUsersByCompanyId(this.caSelected.counterpartySysOrganizationId).then(result => {
                this.cpUsers = result.cpUsers
              })
              this.resetOfflineUserForm()
              this.dialogAddEditUser = false
            }
          })
        }
      })
    },
    updateOfflineUser () {
      this.$refs.offlineUserForm.validate(valid => {
        if (valid) {
          this.$request.put({
            url: `${this.$apis.offlineOrganizationContact}`,
            data: this.offlineUserForm
          }).then(res => {
            if (res?.code === 1000) {
              this.$message.success('User has been updated.')
              this.getOfflineUsersByCompanyId(this.caSelected.counterpartySysOrganizationId).then(result => {
                this.cpUsers = result.cpUsers
              })
              this.resetOfflineUserForm()
              this.dialogAddEditUser = false
            }
          })
        }
      })
    },
    async getOnlineCompanies () {
      const res = await this.$request.get({
        url: `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=9999&offline=false`

      });
      if (res?.code === 1000) {
        const companyList = res.data?.records ?? []
        this.companyList = companyList.filter(d => d.companyName !== 'noCompany')
      }
    },
    getVctoryAddtionalFields(params) {
      const {groupCode,accountSet,billingCycle}=this.caForm
      const victoryCompanyCode = this.caForm?.victoryCompanyCode?.trim()?.toUpperCase() ?? null
      const terms =this.caForm?.terms&& this.caForm?.terms!='' ? Number(this.caForm.terms):null
      // Ensure correct comparison with the value property of the list items
      const isNewCompanyCode = !this.victoryCompanyCodeList.some(item => {
        return item.value.trim().toUpperCase() === victoryCompanyCode;
      })
      // console.log(this.victoryCompanyCodeList)
      return {groupCode,accountSet,billingCycle,victoryCompanyCode,terms, isCreateNewCust: isNewCompanyCode }
    },
    updateOnlineCompany() {
      let id = this.caForm.caId //'1801146749335658496'  //1801146749335658496 //1801146749335658496
      let tierDefId = this.caForm.pricingTier

      this.updateCustomerPricingTier({id, tierDefId,...this.getVctoryAddtionalFields()}).then(res => {
        if( res ) {
          this.cpList.map( (item, index) => {
            if( item.id == id ) {
              this.cpList[index].pricingTier = tierDefId
            }
          })
          this.resetCaForm()
          this.cpIsOnlineTabActive = 'ACTIVE'
          this.dialogCreateEditCa = false
          this.getCps()
          this.getCompanyById(this.caSelected.counterpartySysOrganizationId).then(result => {
            this.cpCompany = result.cpCompany
          })
        }
      }).catch((error) => {
        this.$message.error( `Update failed: ${error} `)
      })
    },
    updateOfflineCompany () {
      this.$refs.caForm.validate(valid => {
        if (valid) {
          const data = this.getOfflineCaFormData()
          data.id = this.caSelected.counterpartySysOrganizationId
          const isNewCompanyCode = !this.victoryCompanyCodeList.some(item => {
            return item.value.trim().toUpperCase() === data.victoryCompanyCode.trim().toUpperCase()
          })
          data.isCreateNewCust = isNewCompanyCode
          this.$request.put({
            url: `${this.$apis.offlineOrganization}`,
            data: data
          }).then(res => {
            if (res?.code === 1000) {
              this.$message.success('Company has been updated')
              this.cpList.map( (item, index) => {
                if( item.id == this.caForm.caId) {
                  this.cpList[index].pricingTier = this.caForm.pricingTier
                }
              })
              this.resetCaForm()
              this.cpIsOnlineTabActive = 'ACTIVE'
              this.dialogCreateEditCa = false
              this.getCps()
              this.getCompanyById(this.caSelected.counterpartySysOrganizationId).then(result => {
                this.cpCompany = result.cpCompany
              })
            }
          })
        }
      })
    },
    getOfflineCaFormData () {
      // const data = {
      //   createdByOnlineSysOrganizationId: this.currentCompany.id,
      //   companyName: this.caForm.companyName,
      //   businessIncorporationNumber: this.caForm.businessIncorporationNumber,
      //   incorporationDate: this.caForm.incorporationDate,
      //   companyType: this.caForm.companyType,
      //   abbreviation: this.caForm.abbreviation,
      //   fax: this.caForm.fax,
      //   incorporationCountry: this.caForm.incorporationCountry,
      //   streetName: this.caForm.streetName,
      //   buildingName: this.caForm.buildingName,
      //   unitNumber: this.caForm.unitNumber,
      //   postal: this.caForm.postal,
      //   email: this.caForm.email,
      //   pricingTier: this.caForm.pricingTier,
      //   caId: this.caForm.caId,
      // }
      const data = {...this.caForm}
      data.createdByOnlineSysOrganizationId = this.currentCompany.id;
      data.victoryCompanyCode = typeof this.caForm?.victoryCompanyCode === 'string'
        ? this.caForm.victoryCompanyCode.trim().toUpperCase()
        : this.caForm.victoryCompanyCode;
      data.terms = typeof this.caForm?.terms === 'string' && this.caForm.terms.trim() !== ''
        ? Number(this.caForm.terms)
        : this.caForm.terms;

      return data;
    },
    handleDeactiveFlagCompany (command) {
      switch (command) {
        case 'DEFAULT_BANK':
          this.dialogBank = true
          break
        case 'DEACTIVATE':
          this.updateActivation(false)
          break
        case 'ACTIVATE':
          this.updateActivation(true)
          break
        case 'FLAG':
          this.updateFlag(true)
          break
        case 'UNFLAG':
          this.updateFlag(false)
          break
      }
    },
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    capitalizeFirstLetter (x) {
      return x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()
    },
    formatCreditInfoDisplay () {
      this.caCreditInfo.creditLimitDisplay = `$ ${this.numberWithCommas(Number(this.caSelected.creditLimit).toFixed(2))}`
      if (String(this.caSelected.totalCreditBalance)[0] === '-') {
        const cb = String(this.caSelected.totalCreditBalance).split('-')
        this.caCreditInfo.totalCreditBalanceDisplay = `- $ ${this.numberWithCommas(Number(cb[1]).toFixed(2))}`
      } else {
        this.caCreditInfo.totalCreditBalanceDisplay = `$ ${this.numberWithCommas(Number(this.caSelected.totalCreditBalance).toFixed(2))}`
      }
      if (String(this.caSelected.totalCreditUsed)[0] === '-') {
        const cu = String(this.caSelected.totalCreditUsed).split('-')
        this.caCreditInfo.totalCreditUsedDisplay = `- $ ${this.numberWithCommas(Number(cu[1]).toFixed(2))}`
      } else {
        this.caCreditInfo.totalCreditUsedDisplay = `$ ${this.numberWithCommas(Number(this.caSelected.totalCreditUsed).toFixed(2))}`
      }
    },
    getbankList () {
      const item1 = {
        category: 'COMPANY_BANK',
        bankList: this.bankList.length > 0 ? this.bankList.filter(l => l.bankType === 'COMPANY_BANK') : []
      }
      const item2 = {
        category: 'CHASER_BANK',
        bankList: this.bankList.length > 0 ? this.bankList.filter(l => l.bankType === 'CHASER_BANK') : []
      }
      this.bankAccList = [item1, item2]
      this.bankLoading = false
    },
    handleBankRadioSelectChange (bankId, refKey) {
      const bank = this.bankList.find(b => b.id === bankId)
      if (refKey === 'companyBank') {
        this.companyDefaultBank = bank
      } else {
        this.chaserDefaultBank = bank
      }
    },
    handleConfirmDefaultBank () {
      this.bankLoading = true
      this.$request.post({
        url: `${this.$apis.creditAssociations}/${this.caSelected.id}/bank`,
        data: {
          companyBankId: this.companyDefaultBank?.id,
          chaserBankId: this.chaserDefaultBank?.id
        }
      }).then(res => {
        if (res?.code === 1000) {
          this.dialogBank = false
          this.$message.success('Default bank has been updated')
        }
      }).finally(() => {
        this.bankLoading = false
      })
    },

    async getPricingTierDefinition() {
      await this.pricingTierDefinition().then( res => {
        if( res ) {
          res.map( item => {
            this.tiers.push({ tierId : item.id, title : item.name })
          })
        }
      })
    },
    getVictoryCompanyCodeList(event,cusId){
      if (event && cusId ) {
        getVictoryCompanyCodeListReq(cusId).then((data) => {
        this.victoryCompanyCodeList = data
      })}
    },
    handleChangeVictoryCompanyCode(val){
      if (val&&val.trim()!='') {
        this.victoryCompanyCode =val.toUpperCase()
      }
    },
    handleChangeVictoryTerms(val){
      if (val&&val.trim()!='') {
        this.victoryCompanyCode =val.toUpperCase()
      }
    },
    getVictoryAccountSetList(event){
      if (event) {
        getVictoryAccountSetListReq().then((data) => {
          this.victoryAccountSetList = data
        })
      }
      // else{
      //   this.victoryAccountSetList = []
      // }
    },
    getVictoryBillingCycleList(event){
      if (event) {
        getVictoryBillingCycleListReq().then((data) => {
        this.victoryBillingCycleList = data
      })
      }
      // else{
      //   this.victoryBillingCycleList = []
      // }
    },
    getVictoryTermsList(event){
      if (event) {
        getVictoryTermsListReq().then((data) => {
          this.victoryTermsList = data
        })
      }
      // else{
      //   this.victoryTermsList = []
      // }
    },
  }

}
</script>
<style lang="scss" scoped>
.div-cus-mgmt-main {
  height: 100%;
  overflow: hidden;
  min-width: 1000px;
  .el-form{
    padding-top: 10px;
  }
}
.div-cus-mgmt-main-title {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  &>h1 {
    color: $primary-color-font;
    text-align: left;
    margin: 0;
    font-size: 20px;
    min-width: 180px
  }
  .el-button {
    margin-left: auto;
  }
}
.div-cus-mgmt-main-body {
  display: grid;
  grid-template-columns: 20% 80%;
  height: calc(100% - 50px);
  overflow: hidden;
  box-shadow: 0 0 5px #eaeaea;
  border: 1px solid #eaeaea;
  border-radius: 20px;
}
.div-cus-mgmt-main-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eaeaea;
  .div-cus-mgmt-main-left-tab {
    margin: 0 5px;
    /deep/.el-tabs__item {
      // font-size: 18px;
      // padding: 5px;
      &:last-child{
        // background-color: #26e877;
        position: relative;
        // position: absolute;
      }
    }
  }
  .div-cus-mgmt-main-left-search {
    width: 95%;
    min-width: 150px;
    margin:10px auto;
     /deep/ .el-input__inner {
      border: none !important;
    }
  }
  .div-cus-mgmt-main-left-list {
    flex: 1 0 1px;
    overflow-y: auto;
    .div-active-cp-list-item {
      background-color: aliceblue;
    }
    &>div {
      box-shadow: 0 0 5px #eaeaea; padding: 10px;
      min-height: 80px;
      min-width: 200px;
      display: grid;
      grid-template-columns: 50px 1fr 24%;
      align-content: center;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      &>img {
        border-radius: 50%;
        border: 1px solid #D5DDED;
      }
      &>p {
        overflow-wrap: anywhere;
        align-self:center;
        margin: 4px;
      }
      &>div p {
        margin: 0;
      }
      .div-active-cp-list-item-no-logo {
        padding: 0; justify-self: left;
      }
      .div-cp-list-item-flagged {
        border: 1px solid #E83126;
        background-color: #FFEAE5;
        align-self: center;
        text-align: center;
        border-radius: 6px;
        width: fit-content;
        padding: 0 4px;
        justify-self: right;
        &>p {
          overflow-wrap: anywhere;
          margin: 2px;
          color: #E83126;
          font-size: 13px;
        }
      }
    }
  }
  .div-company-list-loading {
    text-align: center;
    margin: 40px;
  }
  .div-company-list-no-data {
    text-align: center;
    color: #909399;
    margin: 40px;
  }
}
.div-cus-mgmt-main-right {
  height: 100%;
  display: grid;
  grid-template-rows: minmax(200px, 300px) minmax(200px, 1fr);
  overflow: auto;
  .div-cus-mgmt-main-right-top {
    display: grid;
    grid-template-columns: 80% 20%;
    .div-cus-mgmt-main-company-info {
      border-right: 1px solid #eaeaea;
      height: 100%;
      overflow: hidden;
      padding: 10px;
      .div-cus-mgmt-main-company-info-title-btn {
        display: grid;
        grid-template-columns: 200px 1fr;
        margin: 10px;
        margin-top: 0;
        min-height: 28px;
        align-content: center;
        .div-cus-mgmt-main-company-info-btn {
          &>.el-dropdown span{
            border: 1px solid #DCDFE6;
            border-radius: 50%;
            padding: 4px 5px;
            margin-left: 10px;
          }
        }
        &>div {
          text-align: right;
        }
        &>h3 {
          color: #4C565C;
          margin: 0;
        }
        .el-button {
          height: fit-content;
          width: fit-content;
          justify-self: right;
        }
      }
      .div-cus-mgmt-main-company-info-body {
        display: grid;
        height: calc(100% - 58px);
        flex: 1 0 1px;
        overflow-y: auto;
        &>img {
          justify-self: center;
        }
        .div-cus-mgmt-main-company-info-logo {
          text-align: center;
          .el-avatar {
            font-size: 20px;
          }
        }
        .div-cus-mgmt-main-company-info-body-main {
          display: grid;
          grid-template-columns: 14% 1fr;
          height: 100%;
          .div-cus-mgmt-main-company-info-body-details {
            display: grid;
            grid-template-columns: 32% 32% 32%;
            align-items: baseline;
            height: inherit;
            align-content: space-around;
            >div {
              overflow-wrap: anywhere;
              >h4 {
                margin-bottom: 4px;
                color: #4C565C;
              }
              >p{
                margin: 0 4px;
              }
            }
          }
        }
      }
      .company-logo-name {
        text-align: center;
        margin-top: 14px;
        img {
          border-radius: 100px;
          border : 5px solid #D5DDED
        }
        .company-info-avatar{
          font-size: 30px;
          font-weight: 900;
        }
      }
    }

    .div-cus-mgmt-main-credit-info {
      overflow: hidden;
      height: 100%;
      padding: 10px;
    }
    .div-cus-mgmt-main-credit-info-title-btn {
      display: grid;
      grid-template-columns: 1fr 60px;
      margin: 10px;
      margin-top: 0;
      min-height: 28px;
      align-content: center;
      &>h3 {
        color: #4C565C;
        margin: 0;
      }
      .el-button {
        height: fit-content;
        width: fit-content;
        justify-self: right;
      }
    }
    .div-cus-mgmt-main-credit-info-body {
      flex: 1 0 1px;
      overflow-y: auto;
      height: calc(100% - 70px);
      padding: 0px 14px;
      .div-cus-mgmt-main-credit-info-body-main {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        >div {
          overflow-wrap: anywhere;
          >h4 {
            margin-bottom: 4px;
            color: #4C565C;
          }
          >p{
            margin: 0 4px;
          }
        }
      }
    }
  }
}
.div-cus-mgmt-main-right-loading {
  text-align: center;
  align-self: center;
}
.div-cus-mgmt-main-right-no-data {
  text-align: center;
  align-self: center;
  color: #909399;
}
.el-dialog-create-edit-ca {
  .div-create-edit-ca-form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:start
  }
  .el-form-item{
    width: 300px !important;
    padding-right: 10px;
    /deep/.el-form-item__content{
      width: 100%;
    }

  }


}
.el-dialog-create-edit-user {
  .div-create-edit-user-form-dialog-top {
    display: grid;
    grid-template-columns: 10% 42.5% 42.5%;
    column-gap: 10px;
  }
  .div-create-edit-user-form-dialog-mid {
    display: grid;
    grid-template-columns: 30% 30% 35%;
    column-gap: 10px;
  }
}
/deep/.phone-input {
  width: 100%;
}
/deep/.el-dialog {
  text-align: left;
  width: 80%;
  .el-dialog__footer{
    text-align: right;
    padding: 0;
    margin-top: 20px;
  }
}
/deep/.el-select{
  width: 100%;
}
/deep/.el-table__body tr:hover>td {
  background-color: #EDF7FF;
}
/deep/.el-input-number{
  width: 100%;
  text-align-last: left;
  .el-input__inner {
    text-align: left;
  }
}
.tab-pending{
  background-color: #E83126 !important;
}


</style>
